/**
 * Common Configuration for copy-content on the elevator site.
 */
const typographyVariables = {
    headers: {
        fontWeight: 700,
        fontFamily: 'Open Sans, sans-serif',
    },
    body: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 100,
        fontSize: '16px',
    },
    subtitles: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 700,
    },
};

/**
 * Override default MUI typography type-face for copy-content
 * components
 */
const typographyConfig = {
    typography: {
        h1: {
            ...typographyVariables.headers,
        },
        h2: {
            ...typographyVariables.headers,
        },
        h2: {
            ...typographyVariables.headers,
        },
        h3: {
            ...typographyVariables.headers,
        },
        h4: {
            ...typographyVariables.headers,
        },
        h5: {
            ...typographyVariables.headers,
        },
        h6: {
            ...typographyVariables.headers,
        },
        body1: {
            ...typographyVariables.body,
        },
        body2: {
            ...typographyVariables.body,
        },
        subtitle1: {
            ...typographyVariables.subtitles,
        },
        subtitle2: {
            ...typographyVariables.subtitles,
        },
    },
};

export default typographyConfig;
