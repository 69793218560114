const staticComponentSizesConfig = {
    static_component_sizes: {
        mobile_appbar_height: '50px',
        desktop_appbar_height: '80px',
        notificationBoxHeight: '40px',
        content_max_width: '1100px',
    },
};

export default staticComponentSizesConfig;
