const paletteConfig = {
    palette: {
        primary: {
            main: '#558AC5',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#E6E6E6',
        },
        common: {
            white: '#FFFFFF',
            blueyGrey: '#9EA0A5',
            black: '#000000',
            dodger_blue: '#3B88FD',
            fadedBlue: '#558ac5',
            lightFadedBlue: '#EFF4F9',
            fadedRed: '#FF3F3F',
            fadedGreen: '#35BE95',
            darkYellow: 'rgba(255, 193, 7, 0.16)',
        },
        background: {
            default: '#F6F6F6',
            paper: '#FFFFFF',
        },
        divider: '#D9D9D9',
        branding: {
            elevator_red: '#F82600',
            elevator_grey: '#262626',
            elevator_grey_mid: '#333333',
            elevator_grey_light: '#393939',
            youTube_red: '#FF0000',
            soundCloud_orange: '#FE5000',
            twitter_blue: '#1DA1F2',
        },
        success: {
            main: '#26A69A',
        },
        passwordFeedback: {
            success: '#26A69A',
        },
        submissionStatuses: {
            pending: '#D1D1D1',
            flagged: '#FFDEB3',
            published: '#DAEAFC',
            rejected: '#FFBAB0',
            approved: '#B3E4CC',
        },
        legacyColors: {
            darkInputColors: {
                input: '#C8C8C8',
                inputFocus: '#B72F12',
                inputDisabledBorder: '#393939',
                inputError: '#E62F09',
                inputSuccess: '#00E676',
            },
        },
    },
};

export default paletteConfig;
