const breakpointsConfig = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 850,
            lg: 1280,
            xl: 1920,
        },
    },
};

export default breakpointsConfig;
