import paletteConfig from './palette';

/**
 * Global component style configuration for entire application. This JS object allows us to provide default
 * styling across the application
 *
 * More Info: https://material-ui.com/customization/globals/#css
 */
const overridesConfig = {
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,
                fontFamily: 'Open Sans, sans-serif',
                fontWeight: 600,
            },
            contained: {
                boxShadow: 'none',
            },
        },
        MuiChip: {
            root: { borderRadius: 1 },
        },

        MuiInputLabel: {
            root: {
                fontFamily: 'Open Sans, sans-serif',
                fontWeight: 'bold',
                '&$focused': {
                    color: paletteConfig.palette.common.black,
                },
                fontSize: '1.10rem',
                paddingBottom: '5px',
            },
        },
        MuiInput: {
            root: {
                minHeight: '50px',
                backgroundColor: paletteConfig.palette.secondary.main,
                fontSize: '1em',
            },
            input: {
                paddingLeft: '10px',
                fontFamily: 'Open Sans, sans-serif',
                fontSize: '1em',
            },
            inputMultiline: {
                paddingTop: '10px',
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '0.7rem',
            },
        },
        MuiPaper: {
            root: {
                padding: '8px 16px 30px 16px',
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: paletteConfig.palette.branding.elevator_grey,
            },
        },
    },
};

export default overridesConfig;
