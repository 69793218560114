import { createMuiTheme } from '@material-ui/core/styles';
import typographyConfig from './typography';
import paletteConfig from './palette';
import overridesConfig from './overrides';
import defaultPropsConfig from './props';
import staticComponentSizesConfig from './static_component_sizes';
import breakpointsConfig from './breakpoints';

/**
 * This file allows Material UI to inject the overall theme for this project
 * via the <ThemeProvider /> context.
 *
 * Here we are overriding the default Google Material Design Theme config and providing
 * our own variables which match the Elevator Design System.
 * For more information on how this functions:
 *
 * https://material-ui.com/customization/themes/
 */
const theme = createMuiTheme({
    ...paletteConfig,
    ...typographyConfig,
    ...overridesConfig,
    ...defaultPropsConfig,
    ...breakpointsConfig,
    ...staticComponentSizesConfig,
});

export default theme;
